// @flow
import type { Node } from 'React';
import React from 'react';
import { Grid } from 'components/Editor/Common/Grid/Grid';

import { NormalButtonWithLeftIcon } from '../Common/Buttons/Button';
import { FileUpload } from 'components/Editor/Common/CustomSVGIcons';
import { InputEvent } from 'lib/flowTypes';
import { getImageExtension } from 'lib/helpers';
import { validateExtension } from './lib/helpers';
import { DragAndDropUploader } from '.';
import { MAX_GIF_SIZE } from 'lib/constants';
import styles from './FileUploader.module.css';

const MAX_IMAGE_SIZE = 8388608;

type Props = {
  isUploading: boolean,
  isMobile: boolean,
  resetErrors: () => void,
  setFileUploadErrors: (files: Object) => void,
  uploadFiles: (files: Object[]) => string[],
  mode?: String,
};

export function FileUploader({
  isUploading,
  resetErrors,
  setFileUploadErrors,
  uploadFiles,
  isMobile,
  mode = '',
}: Props): Node {
  let erroredFiles = {
    invalidSizeFiles: [],
    invalidExtensionFiles: [],
    failedFiles: [],
  };

  const validateSize = (size: number, name: string) => {
    if (getImageExtension(name) === 'gif' && mode === 'landingImage') {
      return size <= MAX_GIF_SIZE;
    }
    return size <= MAX_IMAGE_SIZE;
  };

  const validateImage = (name: string, size: number) => {
    if (!validateSize(size, name)) {
      erroredFiles.invalidSizeFiles.push(name);
      return false;
    }
    const extension = getImageExtension(name);
    if (extension && !validateExtension(extension)) {
      erroredFiles.invalidExtensionFiles.push(name);
      return false;
    }

    return true;
  };

  const filterInvalidFiles = (targetFiles: Object[]) => {
    let files = [];

    for (let file of targetFiles) {
      if (!validateImage(file.name, file.size)) {
        continue;
      }

      let data = new FormData();
      data.append('file', file);
      files.push({ data, name: file.name, size: file.size });
    }

    return files;
  };

  const selectImages = async (selectedFiles: Object[]) => {
    const files = filterInvalidFiles(selectedFiles);

    resetErrors();

    if (files.length > 0) {
      erroredFiles.failedFiles = await uploadFiles(files);
    }

    setFileUploadErrors(erroredFiles);
  };

  const handleSelect = (e: InputEvent) => selectImages(e.target.files);

  return (
    <>
      <Grid item sm={12}>
        {!isMobile && (
          <div className={styles.uploader}>
            <DragAndDropUploader
              isUploading={isUploading}
              boxContent="Arrastra aquí una o varias imágenes para subirlas"
              handleFileDrop={selectImages}
            />
          </div>
        )}
      </Grid>
      <Grid item sm={5} xs={12}>
        <Grid
          container
          gutter="custom"
          spacing={2}
          justify={isMobile ? 'center' : 'flex-start'}
        >
          <Grid item>
            <input
              type="file"
              accept="image/*"
              multiple
              disabled={isUploading}
              onChange={handleSelect}
              className={styles.fileSelectHidden}
              id="contained-button-file"
            />
            <label htmlFor="contained-button-file">
              <NormalButtonWithLeftIcon
                label="Subir imágenes"
                icon={FileUpload}
                disabled={isUploading}
                component="span"
              />
            </label>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
