/*@flow*/
import * as React from 'react';
import DOMPurify from 'dompurify';
import type { ComponentType } from 'react';

import {
  User,
  UpdatedTag,
  Tag,
  Items,
  Image,
  CategoryType,
  OptionType,
} from './flowTypes';
import {
  DOM_PURIFY_CONFIG,
  POST_TYPE_BRANDED,
  initialPublishRegions,
  POST_TYPE_LANDING,
  ALL_BRANDED_TYPES,
  ALL_COORDINATORS,
  ROLE_ADMINISTRATOR,
  evergreenCategories,
} from './constants';
import { Link } from 'components/Editor/Common';
import * as siteIcons from 'components/Editor/Common/SiteIcons';
import sites, {
  domains,
  testingDomains,
  devDomains,
  sitesConfig,
} from 'lib/sites';

const MAX_FILE_SIZE = 8388608;

export const YOUTUBE_VIDEO_REGEX: RegExp =
  /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/|live\/)?)([\w-]+)(\S+)?$/i;

export const FACEBOOK_PROFILE_REGEX: RegExp =
  /^(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-.]*\/)*([\w\-.]*)/i;

export const DAILYMOTION_VIDEO_REGEX =
  /^https:\/\/(www\.dailymotion\.com\/video|dai\.ly)\/(\w+)/i;

export const TWITCH_VIDEO_REGEX: RegExp = /^https:\/\/(www\.twitch.tv)\/(\w+)/i;

export const TELEGRAM_URL_REGX: RegExp =
  /^(https?:\/\/)?(www[.])?(telegram|t)\.me\/([a-zA-Z0-9_-]*)\/?$/i;

export const APP_STORE_REGEX: RegExp =
  /^https:\/\/(apps\.apple.com)\/(\w+)\/app\/(\w+)/i;

export const PLAY_STORE_REGEX: RegExp =
  /^https:\/\/(play\.google.com)\/store\/apps\/(\w+)/i;

export const TWITTER_PROFILE_REGEX =
  /^(?:https?:)?\/\/(?:www\.|m\.)?twitter\.com\/(\w{2,15})\/?(?:\?\S+)?(?:\S+)?$/i;

export const LINKEDIN_PROFILE_REGEX =
  /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile|company|showcase)\/([-a-zA-Z0-9]+)\/*/i;

export const TIKTOK_VIDEO_REGEX =
  /^(https:\/\/www\.tiktok\.com\/@)[0-9a-zA-Z_.]{0,23}\w(\/video\/)\d+/;
export const TIKTOK_PROFILE_REGEX =
  /^(?:(?:https?:\/\/)?(?:www\.)?tiktok\.com\/)?@([\w.]{0,23}\w)(?:\/\S*)?$/i;

export const INSTAGRAM_PROFILE_REGEX =
  /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com)\/(\w+)/i;

export const EMAIL_VALIDATION_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/i;

export const PASSWORD_VALIDATION_REGEX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9]).{8,}$/;

export const isValidEmail = (email: string) => {
  return EMAIL_VALIDATION_REGEX.test(email);
};

export const isValidPassword = (password: string) => {
  return PASSWORD_VALIDATION_REGEX.test(password);
};

const DESIGN_MODE_COMPONENTS = ['contentTrain'];
const COMPONENTS_NOT_IN_GRID = [
  'grid',
  'hook',
  'contentTrain',
  'fichaDeReview',
  'pivotInstagram',
  'pivot',
  'pivotEcommerce',
  'pivotExternalArticle',
  'pivotNewsletter',
  'pivotFlipboard',
];

export const AMERICAN_MEXICO_SITES = [
  'xatakamexico',
  'directoalpaladarmexico',
  '3djuegoslat',
  'xatakacolombia',
  'xatakaargentina',
  'xatakaon',
];

const version = process.env.REACT_APP_version || '1';

if (version === '1') {
  COMPONENTS_NOT_IN_GRID.push('singular');
}

export const getNanoMediaCategories = (
  allCategories: Array<Object>
): Array<Object> => {
  let nanoMediaCategories = allCategories.find(
    (catGroup) => catGroup.label === 'Nanomedios'
  );
  nanoMediaCategories = nanoMediaCategories?.options || [];
  return nanoMediaCategories;
};

export const findById = <T: { id: number }>(
  id: number,
  list: Array<T> // eslint-disable-next-line
): T | void => list.find((item: T) => item.id == id);

export const findByName = (name: string, list: Array<User>) =>
  list.find((item) => item.display_name === name);

export const findItemByValue = (
  name: string | number,
  list: Array<Items>,
  key: string = 'value'
) =>
  list.find((item) => {
    if (typeof name === 'string') {
      if (typeof name != 'undefined' && typeof item[key] != 'undefined') {
        return item[key].toLowerCase() === name.toLowerCase();
      }
      return false;
    }

    return item[key] === name;
  });

export const toggleItem = (
  item: string,
  list: Array<string>
): Array<string> => {
  const index = list.indexOf(item);
  return -1 === index
    ? [...list, item]
    : [...list.slice(0, index), ...list.slice(index + 1)];
};

export const filterTags = (tags: Array<Tag>): Array<UpdatedTag> => {
  let updatedTags = [];
  tags.forEach((tag) => {
    updatedTags.push({ id: Number(tag.id), label: tag.name });
  });
  return updatedTags;
};

export const categoryOptions = (
  categories: Array<{
    id: number,
    cat_name: string,
    category_nicename: string,
    category_parent: number,
  }>
): Array<CategoryType> => {
  let updatedCategories = [];
  categories.forEach(
    ({ id, cat_name, category_nicename, category_parent = 1 }) => {
      if (category_parent > 0) {
        updatedCategories.push({
          id: parseInt(id),
          label: cat_name,
          nicename: category_nicename,
        });
      }
    }
  );
  return updatedCategories;
};

export const removeItemByValue = (
  itemValue: string,
  list: Array<{ value: string | number }>
): Array<{ value: string | number, ... }> => {
  const itemIndex = list.findIndex((item) => item.value === itemValue);
  if (-1 === itemIndex) {
    return list;
  }

  return [...list.slice(0, itemIndex), ...list.slice(itemIndex + 1)];
};

export const removeDuplicateOptions = (options: OptionType) => [
  ...new Map(options.map((item) => [parseInt(item.id), item])).values(),
];

export const filterCategories = (
  data: {},
  postType: string,
  isNanomedio: boolean = false
): Array<
  | any
  | {| id: number, is_nanomedio: boolean, label: any |}
  | {|
      label: any,
      options: Array<
        | any
        | {|
            category_nicename: any,
            id: number,
            is_nanomedio: any,
            label: any,
          |}
      >,
    |}
> => {
  let categories = [];
  for (let key in data) {
    let categoryGroup = data[key]['children'];
    if (undefined !== categoryGroup) {
      let options = [];
      categoryGroup.forEach(function (category) {
        if (!isNanomedio || category['is_nanomedio']) {
          options.push({
            label: category['cat_name'],
            id: Number(category['id']),
            is_nanomedio: category['is_nanomedio'],
            category_nicename: category['category_nicename'],
          });
        }
      });

      if (options.length) {
        categories.push({
          label: data[key]['cat_name'],
          options: options,
        });
      }
    } else if (POST_TYPE_LANDING === postType) {
      // To handle post categories which are not in parent child hierarchy
      let category = data[key];
      categories.push({
        id: Number(category['id']),
        label: category['cat_name'],
        is_nanomedio: false,
      });
    }
  }
  return categories;
};

export const mapPostType = (postType: string): any | string => {
  if (ALL_BRANDED_TYPES.includes(postType)) {
    return POST_TYPE_BRANDED;
  }

  return postType;
};

export const queryBuilder = (obj: string | {}, prefix: string = ''): string => {
  if ('string' === typeof obj) {
    return obj;
  }
  return Object.keys(obj)
    .reduce((accumulator, key) => {
      const child = obj[key];
      const childKey = '' === prefix ? key : `${prefix}[${key}]`;
      if (child && 'object' === typeof child) {
        accumulator.push(queryBuilder(child, childKey));
      } else {
        accumulator.push(`${childKey}=${encodeURIComponent(child)}`);
      }
      return accumulator;
    }, [])
    .join('&');
};

export const validateJson = (json: string) => {
  try {
    let jsonToValidate = json.replace(/(\r\n|\n|\r)/gm, '');
    const matches = jsonToValidate.match(
      /^<script type="application\/ld\+json">(.*?)<\/script>$/
    );
    if (!matches) {
      return '';
    }
    jsonToValidate = matches[1];
    if (jsonToValidate && JSON.parse(jsonToValidate)) {
      return json;
    }
    return '';
  } catch (e) {
    return '';
  }
};

export const validateHtml = (html: string, type: string = 'html'): any => {
  if (type === 'json') {
    return validateJson(html);
  }
  return DOMPurify.sanitize(html, DOM_PURIFY_CONFIG);
};

export const findByCatId = (
  id: number,
  list: Object
): void | {| id: number, is_nanomedio: any, label: any |} => {
  for (let key in list) {
    let categoryGroup = list[key]['options'];
    if (undefined !== categoryGroup) {
      for (let index in categoryGroup) {
        let category = categoryGroup[index];
        if (category.id === id) {
          return {
            id,
            label: category.label,
            is_nanomedio: category.is_nanomedio,
          };
        }
      }
    } else {
      let category = list[key];
      if (category.id === id) {
        return {
          id,
          label: category.label,
          is_nanomedio: category.is_nanomedio,
        };
      }
    }
  }
};

export const getDisplayName = (component: ComponentType<any>): string => {
  if (!component) {
    return '';
  }

  return component.displayName || component.name || 'Component';
};

export const getImageExtension = (name: string): string => {
  const extensionIndex = name ? name.lastIndexOf('.') : -1;
  return extensionIndex > 0 ? name.substr(extensionIndex + 1) : '';
};

export const validateExtension = (
  extension: string
): null | RegExp$matchResult =>
  extension.toLowerCase().match(/(jpg|jpeg|png|gif)$/g);

export const validateSize = (size: number) => size <= MAX_FILE_SIZE;

export const getImageName = (image: Image): string =>
  `${image.src.split('/').pop()}.${image.extension}`;

export const getImageData = (
  url: string
): {| extension: string, src: string |} => {
  return {
    src: url.substr(0, url.lastIndexOf('/')),
    extension: getImageExtension(url),
  };
};

export const ucfirst = (data: string): string =>
  data.charAt(0).toUpperCase() + data.slice(1);

export const isValidUrl = (url: string): boolean => {
  const urlRegex =
    /^(?:(?:https?):)?(\/\/)?(www\.)?[a-zA-Z0-9@:%._\-+~#=]{1,256}\.[a-zA-Z]{2,6}\b(?:[-a-zA-Z0-9()@:%_'+.\-~#?&/=,*]*)$/i;
  return urlRegex.test(url);
};

export const isValidInteger = (value: string) => {
  const integerRegex = /^\d+$/;
  return integerRegex.test(value);
};

export function getSiteName(value: string) {
  const match = value.match(
    /https?:\/\/(test\.)?(\w+)\.([\w-]+)\.(?:(\w{2,})(?:\.(\w{2}))?)/i
  );

  if (!match) {
    return null;
  }

  let blogName = match[3];

  if (!['www', 'testing', 'dev'].includes(match[2])) {
    blogName += match[2];
  }

  if (match[4] !== 'com') {
    blogName += match[4];
  }

  const regionMapping = { mx: 'mexico', co: 'colombia' };
  const region = match[5];

  if (region && !!regionMapping[region]) {
    blogName += regionMapping[region];
  }

  return blogName;
}

export const isUrlBelongsToOurSites = (url: string) => {
  const siteName = getSiteName(url);
  return sitesConfig.findIndex((site) => site.value === siteName) >= 0;
};

export const isQueryParamsExist = (url: string): boolean => {
  const queryParamsRegex = /[?&][^=]+=[^&]+/;
  return queryParamsRegex.test(url);
};

export const isPreviewUrl = (url: string): boolean => {
  const previewURLRegex =
    /^(?:(?:https?):)?(\/\/)?(www\.)?[a-zA-Z0-9@:%._\-+~#=]{1,256}\.com(\.co|\.mx|\.lat|)?\/(preview|preview-main)\b(?:[-a-zA-Z0-9()@:%_'+.\-~#?&/=,*]*)$/i;
  return previewURLRegex.test(url);
};

export const isEventUrl = (url: string): boolean => {
  const eventURLRegex = /^https:\/\/(?:\w+\.)+\w+\/event\/([a-zA-Z0-9_-]+)$/i;
  return eventURLRegex.test(url);
};

export const getEventName = (url: string) => {
  const eventRegex = /\/event\/([^/]+)$/;
  const match = url.match(eventRegex);
  return match ? match[1] : '';
};

export const isRedirectUrl = (url: string): boolean => {
  const redirectURLRegex = /\/redirect[/?]/i;
  return redirectURLRegex.test(url);
};

export const isMrfhudParamsExist = (url: string): boolean => {
  if (url === '' || !isValidUrl(url)) {
    return false;
  }
  const urlObject = new URL(url.toLocaleLowerCase());
  const searchParams = new URLSearchParams(urlObject.search);
  return searchParams.has('mrfhud');
};

export const getPostUrlErrorText = (url: string): string => {
  let errorText = '';

  if (isMrfhudParamsExist(url)) {
    errorText =
      'Error: no se pueden incluir parámetros de herramientas de uso interno en enlaces a contenido de Webedia';
  }

  if (isPreviewUrl(url)) {
    errorText = 'Error: no se pueden incluir enlaces a vista previa';
  }

  if (isRedirectUrl(url)) {
    errorText = 'Error: no se pueden incluir enlaces a redirects explícitos';
  }

  return errorText;
};

export const isDesignModeAvailable = (postType: string): boolean =>
  POST_TYPE_LANDING === postType;

export const isNotAllowedInColumnLayout = (componentType: string): boolean =>
  COMPONENTS_NOT_IN_GRID.includes(componentType);

export const isCurrencyMismatch = (
  siteName: string,
  currency: string
): boolean => {
  const isMexicanSite = AMERICAN_MEXICO_SITES.includes(siteName);
  const isMexicanCurrency = currency === '$' || currency === 'MXN';

  return isMexicanSite !== isMexicanCurrency;
};

export const isCountryMismatch = (
  siteName1: string,
  siteName2: string
): boolean => {
  const isMexicanSite1 = AMERICAN_MEXICO_SITES.includes(siteName1);
  const isMexicanSite2 = AMERICAN_MEXICO_SITES.includes(siteName2);

  return isMexicanSite1 !== isMexicanSite2;
};

export const isComponentAvailable = (
  componentType: string,
  postType: string
): boolean => {
  if (
    !isDesignModeAvailable(postType) &&
    DESIGN_MODE_COMPONENTS.includes(componentType)
  ) {
    return false;
  }

  return true;
};

export const formatLayout = (layout: string, postType: string): string => {
  if (!isDesignModeAvailable(postType) && 'edge' === layout) {
    return 'large';
  }

  return '';
};

export const getInitialPublishRegions = (
  siteName: string,
  postType: string = 'normal'
): any | Array<string> => {
  if (isDesignModeAvailable(postType)) {
    return ['ES'];
  }

  return initialPublishRegions;
};

export const getImageUrl = (
  imageData: Image,
  dimension: string = 'original'
): string =>
  imageData.src ? `${imageData.src}/${dimension}.${imageData.extension}` : '';

export const isDesignModeAccessible = (
  postType: string,
  userRole: string
): any | boolean => {
  return (
    isDesignModeAvailable(postType) &&
    [...ALL_COORDINATORS, ROLE_ADMINISTRATOR].includes(userRole)
  );
};

export const getSiteIcon = (
  siteName: string,
  adminUrl: string
): null | React.Node => {
  if (!siteName) {
    return null;
  }
  const siteIconName =
    siteName.charAt(0) === '3' ? ucfirst(siteName.slice(1)) : ucfirst(siteName);
  const Icon = siteIcons[siteIconName];
  return Icon ? (
    <Link href={adminUrl}>
      <Icon />
    </Link>
  ) : null;
};

export const isYoutubeVideo = (url: string): boolean =>
  YOUTUBE_VIDEO_REGEX.test(url);

export const isDailymotionVideoUrl = (url: string): boolean =>
  DAILYMOTION_VIDEO_REGEX.test(url);

export const isInstagramUrl = (url: string): boolean =>
  INSTAGRAM_PROFILE_REGEX.test(url);

export const isTwitterUrl = (url: string): boolean =>
  TWITTER_PROFILE_REGEX.test(url);

export const isLinkedInUrl = (url: string): boolean =>
  LINKEDIN_PROFILE_REGEX.test(url);

export const isTelegramUrl = (url: string): boolean =>
  TELEGRAM_URL_REGX.test(url);

export const isFacebookUrl = (url: string): boolean =>
  FACEBOOK_PROFILE_REGEX.test(url);

export const isTiktokVideoUrl = (url: string): boolean =>
  TIKTOK_VIDEO_REGEX.test(url);
export const isTiktokProfileUrl = (url: string): boolean =>
  TIKTOK_PROFILE_REGEX.test(url);

export const checkInternalUrl = (
  url: string,
  siteName: string = ''
): boolean => {
  const { hostname } = new URL(url);
  if (hostname === siteName) {
    // by passing condition if url host and host is same. Needed in case of xatakaon
    return true;
  }
  let validDomains = [];

  if ('1' === version) {
    validDomains = domains;
  } else if ('2' === version) {
    validDomains = testingDomains;
  } else {
    validDomains = devDomains;
  }

  if (!validDomains.includes(hostname)) {
    return false;
  }

  return true;
};

export const getSiteDisplayName = (siteNicename: string): any | string =>
  sites[siteNicename] ? sites[siteNicename].label : siteNicename;

export const getMonthFilterOptions = (): Array<
  | any
  | {|
      id: number,
      label: number,
      options: Array<any | {| id: string, label: string |}>,
    |}
> => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const monthOptions = [];

  for (let year = currentYear; year > 2004; year--) {
    const startMonth = year === currentYear ? currentMonth : 12;
    const yearOptions = [];
    for (let index = startMonth; index > 0; index--) {
      const monthIndex = index.toString().padStart(2, '0');
      yearOptions.push({
        id: `${year}-${monthIndex}`,
        label: `${monthIndex}/${year}`,
      });
    }
    monthOptions.push({ id: year, label: year, options: yearOptions });
  }

  return monthOptions;
};

export const usePrevious = (value: any): any | void => {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

export const getCroppedImg = (
  image: Object,
  crop: { width: number, height: number, x: number, y: number, unit: string },
  type: string
) => {
  let finalCrop =
    crop.unit === 'px'
      ? crop
      : {
          width: (crop.width * image.width) / 100,
          height: (crop.height * image.height) / 100,
          x: (crop.x * image.width) / 100,
          y: (crop.y * image.height) / 100,
        };

  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = finalCrop.width * scaleX;
  canvas.height = finalCrop.height * scaleY;
  const ctx = canvas.getContext('2d');
  image.crossOrigin = 'anonymous';

  ctx.drawImage(
    image,
    finalCrop.x * scaleX,
    finalCrop.y * scaleY,
    finalCrop.width * scaleX,
    finalCrop.height * scaleY,
    0,
    0,
    finalCrop.width * scaleX,
    finalCrop.height * scaleY
  );
  const base64Image = canvas.toDataURL(type);

  return base64Image;
};

export const getImageType = (extension: string) => {
  switch (extension) {
    case 'gif':
      return 'image/gif';

    case 'png':
      return 'image/png';
    default:
      return 'image/jpeg';
  }
};

const isEvergreenCategory = (siteName: string, categoryNicename: string) => {
  if (!evergreenCategories.hasOwnProperty(siteName) || !categoryNicename) {
    return false;
  }

  if (evergreenCategories[siteName].indexOf(categoryNicename.trim()) > -1) {
    return true;
  }

  return false;
};

export const isEvergreenCategoryMapped = (
  siteName: string,
  primaryCategoryNicename: string,
  postCategories: Array<{ category_nicename: string }>
) => {
  if (isEvergreenCategory(siteName, primaryCategoryNicename)) {
    return true;
  }

  const category = postCategories.find((category) =>
    isEvergreenCategory(siteName, category.category_nicename)
  );
  if (category) {
    return true;
  }

  return false;
};

const calculateCrop = (imageAspectRatio, cropAspectRatio) => {
  if (imageAspectRatio > cropAspectRatio) {
    const width = (cropAspectRatio * 100) / imageAspectRatio;
    const x = (100 - width) / 2;

    return {
      aspect: cropAspectRatio,
      unit: '%',
      x: x,
      y: 0,
      height: 100,
      width: width,
      validate: false,
    };
  } else {
    const height = (imageAspectRatio * 100) / cropAspectRatio;
    const y = (100 - height) / 2;

    return {
      aspect: cropAspectRatio,
      unit: '%',
      x: 0,
      y: y,
      width: 100,
      height: height,
      validate: false,
    };
  }
};

export const getInitialCrop = (imageAspectRatio: number) => {
  return {
    square: calculateCrop(imageAspectRatio, 1),
    golden: calculateCrop(imageAspectRatio, 1.5),
    panoramic: calculateCrop(imageAspectRatio, 2.618),
  };
};

/**
 * It will return substring of given length.
 * If space present in a string and its position is inside selection then
 * It will return till space else the whole selection part.
 */
export const getTrimmedText = (text: string, limit: number) => {
  if (limit >= text.length) {
    return text;
  }
  const regex = new RegExp(`^((.{0,${limit}})\\s|(.{0,${limit}}))`, 'g');
  const match = text.match(regex);

  return match ? `${match[0].trim()}...` : '';
};

export const getUrlError = (url: string) => {
  if (!url) {
    return 'Error: Falta la URL';
  }
  if (!isValidUrl(url)) {
    return 'Error: la URL no tiene un formato válido';
  }
  return '';
};

export const getContentHashCode = (content: string) => {
  const contentLength = content.length;
  let hash = 0;
  if (0 === contentLength) {
    return hash;
  }
  for (let i = 0; i < contentLength; i++) {
    const char = content.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }

  return hash;
};

export const isMobileDeviceEnabled = (siteName: string) =>
  siteName.startsWith('xataka');

export const isVideojuegoEnabled = (siteName: string) =>
  siteName.startsWith('3djuegos');

export const removeEmptySpace = (title: string) =>
  title.replace(/^\s*[\r\n]/gm, '');

export const is3dJuegos = (siteName: string) => '3djuegos' === siteName;

export const dumpJson = (content: Object, firebaseId: string) => {
  const { postType } = content;
  const element = document.createElement('a');
  const hashCode = getContentHashCode(JSON.stringify(content));
  const encodedContent = btoa(
    unescape(encodeURIComponent(JSON.stringify({ ...content, hashCode })))
  );
  const file = new Blob([encodedContent], {
    type: 'application/octet-stream',
  });
  element.href = URL.createObjectURL(file);
  const filePrefix =
    postType === POST_TYPE_LANDING ? POST_TYPE_LANDING : 'post';
  element.download = `${filePrefix}${firebaseId}-backup.dat`;
  if (document.body) {
    document.body.appendChild(element); // Required for this to work in FireFox
  }
  element.click();
};

export const getSubStringWithEllipsis = (
  value: string,
  noOfCharAllowed: number
) => {
  if (value.length <= noOfCharAllowed) {
    return value;
  }

  return value.substring(0, noOfCharAllowed) + '...';
};

export const joinArrayInReadableFormat = (array: Array<Object>) => {
  const input = [...array];
  const last = input.pop();
  const result = input.join(', ') + ' y ' + last;
  return result;
};

export const getUserOptions = (userList: Object) => {
  const duplicateDisplayName = {};
  Object.keys(userList).forEach((index) => {
    const key = userList[index].display_name.toLowerCase();
    if (duplicateDisplayName.hasOwnProperty(key)) {
      duplicateDisplayName[key] += 1;
    } else {
      duplicateDisplayName[key] = 1;
    }
  });
  const mappedUsers = userList.map(({ display_name, id, user_login }) => ({
    label:
      duplicateDisplayName[display_name.toLowerCase()] > 1
        ? `${display_name} (${user_login})`
        : display_name,
    value: id,
  }));
  mappedUsers.sort((userA, userB) => {
    const userAlabel = userA.label || '';
    const userBlabel = userB.label || '';
    return userAlabel.localeCompare(userBlabel);
  });

  return mappedUsers;
};

export const trimEmptyLines = (str: string) => {
  if (!str) {
    return str;
  }
  return str.replace(/\n<p><br[/]?><[/]?p>/g, '');
};

export const hexColorRegex = /^#([0-9A-Fa-f]{6})$/;

export const getH2Count = (sections: Array<Object>) => {
  const content = getContent(sections);
  const matches = Array.from(
    content.matchAll(/<(h2)([^<]+)?>((.|\n)+?)<\/\1>/gim)
  );
  if (!matches) {
    return 0;
  }

  return matches.length;
};

export const getContent = (sections: Array<Object>) => {
  let content = '';
  sections.forEach(({ isHidden = false, ...section }) => {
    if (section.type === 'content' && !isHidden) {
      content += section.text;
    } else if (section.type === 'grid' && !isHidden) {
      for (const column of section.columns) {
        for (const { type, isHidden = false, ...cell } of column) {
          if (type === 'content' && !isHidden) {
            content += cell.text;
          }
        }
      }
    }
  });

  return content;
};

export const stripTags = (content: string) => {
  const doc = new DOMParser().parseFromString(content, 'text/html');
  return doc.body?.textContent.replace(/\s\s+/g, ' ') || '';
};

export const getPreviewUrl = (siteUrl: string, firebaseId: string) =>
  `${siteUrl}/preview/${firebaseId}`;
