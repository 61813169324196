// @flow
import type { Node } from 'React';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { ToolTipText } from 'components/Editor/Common/Tooltip';
import { Caption } from 'components/Editor/Common/Typography/Typo';
import { TransparentIconButton } from 'components/Editor/Common/Buttons/Button';
import {
  SaveIcon,
  ShuffleIcon,
  VisibilityIcon,
} from 'components/Editor/Common/Icons';
import { Grid } from 'components/Editor/Common';
import { InputEvent } from 'lib/flowTypes';
import { PREVIEW_PATH, FICHA_MOVILES_PREVIEW } from 'constants/index';
import { ModeButton } from 'components/Editor/Common';
import { toggleDesignMode } from 'actions/common';
import { Check } from 'lib/check';
import styles from './MenuIcons.module.css';

type Props = {
  siteUrl: string,
  activeTab: string,
  statusMsg: string,
  hasTitleError: boolean,
  postName: string,
  saveData: () => void,
  hideStatus: () => void,
  handleErrors: () => void,
  showPostStatusMsg: boolean,
  isMobile: boolean,
  toggleOrderMode: () => void,
  isDesignMode: boolean,
  toggleDesignMode: () => void,
  setAnchorSource: Function,
};

const ESCRIBIR = 'edit/post';

export class MenuIcons extends PureComponent<Props> {
  onPreviewClick = (event: InputEvent) => {
    const { activeTab, handleErrors, hasTitleError, setAnchorSource } =
      this.props;
    if ('escribir' === activeTab && hasTitleError) {
      handleErrors();
      event.preventDefault();
    }
    setAnchorSource(null, null);
  };

  getStatusElement(): null | Node {
    const { statusMsg, showPostStatusMsg } = this.props;
    let statusMsgElement = null;

    if (showPostStatusMsg) {
      statusMsgElement = <Caption>{statusMsg}</Caption>;
    }
    return statusMsgElement;
  }

  handleDesignModeClick: () => void = () => {
    this.props.setAnchorSource(null, null);
    this.props.toggleDesignMode();
  };

  getPreviewUrl(): string {
    const { siteUrl, activeTab, postName } = this.props;
    if (!siteUrl) {
      return '';
    }
    let path = PREVIEW_PATH;
    if ('producto' === activeTab || 'ficha' === activeTab) {
      path = FICHA_MOVILES_PREVIEW;
    }

    return siteUrl + path + postName;
  }

  render(): Node {
    const { activeTab, toggleOrderMode, saveData, isDesignMode } = this.props;
    return (
      <>
        <Grid
          container
          gutter="custom"
          spacing={0}
          alignItems="center"
          justify="flex-end"
          wrap="nowrap"
        >
          <Grid item>
            <span className={styles.margin}>{this.getStatusElement()}</span>
          </Grid>

          <Grid item>
            {['escribir', ESCRIBIR, 'backup'].indexOf(activeTab) > -1 && (
              <ToolTipText title="Guardar">
                <span>
                  <TransparentIconButton onClick={saveData} color={'inherit'}>
                    <SaveIcon />
                  </TransparentIconButton>
                </span>
              </ToolTipText>
            )}{' '}
            {activeTab === ESCRIBIR && (
              <TransparentIconButton
                onClick={toggleOrderMode}
                color={'inherit'}
              >
                <ShuffleIcon />
              </TransparentIconButton>
            )}
          </Grid>
          <Grid item>
            <Check childName="DesignMode">
              {activeTab === 'escribir' && (
                <ToolTipText
                  title={
                    isDesignMode
                      ? 'Herramientas de escritura'
                      : 'Herramientas de diseño'
                  }
                >
                  <span className={styles.iconCursor}>
                    <ModeButton
                      onClick={this.handleDesignModeClick}
                      mode="design"
                      bgcolor={isDesignMode ? 'green' : 'black'}
                    />
                  </span>
                </ToolTipText>
              )}
            </Check>
          </Grid>

          <Grid item>
            <ToolTipText title="Vista previa">
              <span>
                <TransparentIconButton
                  target="_blank"
                  onClick={this.onPreviewClick}
                  href={this.getPreviewUrl()}
                  color={'inherit'}
                  rel="noopener"
                >
                  <VisibilityIcon />
                </TransparentIconButton>
              </span>
            </ToolTipText>
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isDesignMode: state.common.isDesignMode,
  };
};

export default (connect(mapStateToProps, { toggleDesignMode })(MenuIcons): any);
