import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { AppBar } from 'components/Editor/Common';
import { Grid } from 'components/Editor/Common/Grid/v2/Grid';
import MenuTabs from './MenuTabs';
import { setShowErrors } from 'actions/post';
import { openModal } from 'actions/modal';
import { setAnchorSource } from 'actions/common';
import MenuIcons from './MenuIcons';
import { MenuTitle } from './MenuTitle';

type Props = {
  activeTab: string,
  hasTitleError: string,
  postName: string,
  navigate: Object,
  showPostStatusMsg: boolean,
  statusMsg: string,
  siteUrl: string,
  adminUrl: string,
  showDifundir: boolean,
  blogName: string,
  postType: string,
  hasErrors: boolean,
  showErrors: () => void,
  saveData: () => void,
  toggleOrderMode: () => void,
  hideStatus: () => void,
  isMobile: boolean,
  openModal: (modalName: string) => void,
  isDesignMode?: boolean,
  setAnchorSource: Function,
  moduleName: string,
  setShowErrors: (show: boolean) => void,
  postId: string | number,
  setIsUnsavedChanges: (data: boolean) => void,
  setShowMessage: (data: boolean) => void,
};

export class TitleBar extends PureComponent<Props> {
  handleErrors = () => {
    const { setShowErrors } = this.props;
    setShowErrors(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  render() {
    const {
      siteUrl,
      adminUrl,
      showDifundir = false,
      blogName,
      postType,
      activeTab,
      postName,
      hasErrors,
      navigate,
      statusMsg,
      showPostStatusMsg,
      hideStatus,
      hasTitleError,
      setAnchorSource,
      isMobile = false,
      isDesignMode = false,
      moduleName,
      setShowErrors,
      postId,
      setIsUnsavedChanges,
      setShowMessage,
    } = this.props;

    return (
      <AppBar isDesignMode={isDesignMode}>
        <Grid
          container
          gutter="custom"
          spacing={0}
          alignItems="flex-end"
          style={{ height: 'inherit' }}
        >
          <Grid
            item
            sm={6}
            xs={5}
            md={3}
            lg={4}
            style={{ alignSelf: 'center' }}
          >
            <MenuTitle
              adminUrl={adminUrl}
              blogName={blogName}
              isDesignMode={isDesignMode}
              postType={postType}
              moduleName={moduleName}
              postId={postId}
            />
          </Grid>

          <Grid item lg={4} md={6} xs={12} sx={{ order: { xs: 3, md: 2 } }}>
            <MenuTabs
              activeTab={activeTab}
              postName={postName}
              hasErrors={hasErrors}
              handleErrors={this.handleErrors}
              navigate={navigate}
              showDifundir={showDifundir}
              postType={postType}
              setAnchorSource={setAnchorSource}
              moduleName={moduleName}
              setShowErrors={setShowErrors}
              setIsUnsavedChanges={setIsUnsavedChanges}
              setShowMessage={setShowMessage}
            />
          </Grid>

          <Grid
            item
            sm={6}
            xs={7}
            md={3}
            lg={4}
            style={{ alignSelf: 'center' }}
            sx={{ order: { xs: 2, md: 3 } }}
          >
            <MenuIcons
              isMobile={isMobile}
              siteUrl={siteUrl}
              activeTab={activeTab}
              showPostStatusMsg={showPostStatusMsg}
              statusMsg={statusMsg}
              postName={postName}
              hideStatus={hideStatus}
              handleErrors={this.handleErrors}
              hasTitleError={hasTitleError}
              setAnchorSource={setAnchorSource}
            />
          </Grid>
        </Grid>
      </AppBar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    postType: state.post.postType,
    hasErrors: state.errors ? state.errors.hasErrors : false,
    hasTitleError: state.errors && state.errors.title,
    isDesignMode: state.common.isDesignMode,
    postId: state.post.postId,
  };
};

export default connect(mapStateToProps, {
  setShowErrors,
  openModal,
  setAnchorSource,
})(TitleBar);
