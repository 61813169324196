import * as React from 'react';
import NumberFormat from 'react-number-format';
import {
  DeleteIcon,
  DefaultDeleteIcon,
  ArrowBackIcon,
} from 'components/Editor/Common/Icons';
import memoize from 'memoize-one';
import { getImageExtension, validateSize } from 'lib/helpers';
import { searchEcommerceProduct } from './service';
import { Section } from 'lib/flowTypes';
import { isMexicoSite as isMexicoSiteAdminUrl } from 'components/Editor/Common/lib/helpers';

const MAX_FILE_SIZE = 8388608;
const INVALID_IMAGE_EXTENSION =
  'No es una imágen válida. Puedes usar JPG y PNG';
const ERROR_INVALID_SIZE = 'Es mayor de 8MB';
const getInvalidDimensionError = (MIN_IMAGE_HEIGHT, MIN_IMAGE_WIDTH) =>
  `No es una imágen válida. Puedes usar Mínimo ${MIN_IMAGE_HEIGHT}x${MIN_IMAGE_WIDTH}`;

export const cancelLabel = (label: string) => (
  <React.Fragment>
    <ArrowBackIcon fontSize="small" className="navigation-icon-back" />
    {label}
  </React.Fragment>
);

export const deleteModalLabel = (label: string) => (
  <React.Fragment>
    <DeleteIcon color="inherit" fontSize="small" />
    {label}
  </React.Fragment>
);

export const deleteLabel = (label: string) => (
  <React.Fragment>
    <DefaultDeleteIcon color="inherit" fontSize="small" />
    {label}
  </React.Fragment>
);

export const getAsinFromUrl = (url: string, isMexicoSite: boolean) => {
  let matches;
  if (isMexicoSite) {
    matches = url.match(
      /(?:(?:https?):)?\/\/www\.amazon\.com\.mx\/(?:[\wÀ-ÖØ-öø-ÿ-%]+\/)?(?:dp|gp\/product|asin)\/(?:[\w-%]+\/)?(\w{10})/i
    );
  } else {
    matches = url.match(
      /(?:(?:https?):)?\/\/www\.amazon\.es\/(?:[\wÀ-ÖØ-öø-ÿ-%]+\/)?(?:dp|gp\/product|asin)\/(?:[\w-%]+\/)?(\w{10})/i
    );
  }
  if (matches) {
    return matches[1];
  }

  return '';
};

export const getCurrencyName = (symbol: string) => {
  return ['€', 'EUR'].includes(symbol) ? 'EUR' : 'MXN';
};

export const getCurrencySymbol = (currency: string) => {
  switch (currency) {
    case 'EUR':
      return '€';
    case 'MXN':
    case 'USD':
      return '$';
    default:
      return currency;
  }
};

export const formatProductPrice = (priceData: string) => {
  const data = priceData.trim().split(' ');
  const currency = data[0];
  const price = data[1] ? getFormattedPrice(data[1], currency) : '';

  return { price, currency };
};

export const getDisplayPrice = (
  price: string,
  currency: string,
  dense: boolean = false,
  highlightPrice: boolean = false
) => {
  const space = dense ? '' : ' ';
  if (currency === '$' || currency === 'MXN' || currency === 'USD') {
    return (
      <NumberFormat
        value={price}
        displayType={'text'}
        thousandSeparator={','}
        decimalSeparator={'.'}
        decimalScale={2}
        fixedDecimalScale
        isNumericString
        renderText={(formattedValue) => (
          <span>
            {getCurrencySymbol(currency)}
            {highlightPrice ? (
              <strong>{formattedValue}</strong>
            ) : (
              formattedValue
            )}
          </span>
        )}
      />
    );
  } else {
    return (
      <NumberFormat
        value={price}
        displayType={'text'}
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={2}
        fixedDecimalScale
        isNumericString
        suffix={space + getCurrencySymbol(currency)}
      />
    );
  }
};

export const getFormattedPrice = (price: string, currency: string) => {
  if (typeof price === 'number') {
    return price.toFixed(2);
  }
  if (currency === '$' || currency === 'MXN' || currency === 'USD') {
    return parseFloat(price.replace(',', '')).toFixed(2);
  }
  const formattedPrice = price.replace('.', '').replace(',', '.');
  return parseFloat(formattedPrice).toFixed(2);
};

export const getEsFormattedPrice = (price: string) =>
  formatPrice(price, 'de-DE');

export const getMxFormattedPrice = (price: string) =>
  formatPrice(price, 'es-Mx');

const formatPrice = (price, locale) => {
  return isNaN(Number(price))
    ? price
    : Number(price).toLocaleString(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
};

export const getEbayIdFromUrl = (url: string) => {
  /*eslint-disable*/
  const matches = url.match(
    /ebay\.es\/.+?\/(?:(\d+)[^\/]*$|[^\?]+\?.*?iid=(\d+))/i
  );
  if (matches) {
    return matches[1] || matches[2];
  }
  return '';
};

export const isEbayId = (id: string) => id.match(/^\d+$/);

export const isValidAsin = (asin: string) => asin.match(/^[\w\d]{10}$/);

export const isDollarSite = memoize((adminUrl: string) => {
  return isMexicoSite(adminUrl) || isEnglishSite(adminUrl);
});

export const isMexicoSite = memoize((adminUrl: string) =>
  isMexicoSiteAdminUrl(adminUrl)
);

export const isEnglishSite = memoize((adminUrl: string) => {
  return adminUrl.includes('xatakaon.com');
});

export const getEcommerceProducts = async (
  adminUrl: string,
  searchTerm: string,
  store = ''
) => {
  const mexicoSite = isDollarSite(adminUrl);
  searchTerm = searchTerm.trim();
  let criteria = 'keywords';
  let ebayId = mexicoSite ? '' : getEbayIdFromUrl(searchTerm);
  let asin = getAsinFromUrl(searchTerm, mexicoSite);

  if (asin) {
    store = 'Amazon';
    criteria = 'asin';
    searchTerm = asin;
  } else if (ebayId) {
    store = 'Ebay';
    criteria = 'id';
    searchTerm = ebayId;
  } else {
    if ('Amazon' === store && isValidAsin(searchTerm)) {
      criteria = 'asin';
    } else if ('Ebay' === store && isEbayId(searchTerm)) {
      criteria = 'id';
    } else if ('' === store) {
      return { storeName: store, products: [] };
    }
  }

  const products = await searchEcommerceProduct(
    adminUrl,
    store,
    criteria,
    searchTerm
  );
  return { products, storeName: store };
};

export const copySection = (
  firebaseId,
  section: { id: number, type: string }
) => {
  const copiedSection = { section, postId: firebaseId };
  localStorage.setItem('copiedSection', JSON.stringify(copiedSection));
};

export const getCopiedSection = () => {
  const copiedSection = localStorage.getItem('copiedSection');
  return copiedSection ? JSON.parse(copiedSection) : null;
};

export const showForegroundColorToolbar = (type) => {
  return [
    'title',
    'content',
    'image',
    'summary',
    'richContent',
    'fichaDeReview',
    'pivotEcommerce',
    'pivotNewsletter',
    'pivot',
    'pivotFlipboard',
    'pivotInstagram',
    'pivotExternalArticle',
    'hook',
    'table',
    'contentTrain',
    'grid',
    'tableOfContents',
  ].includes(type);
};

export const getSection = (index: string = '', sections: Section[]) => {
  if (!index) {
    return {};
  }

  let section = {};
  const isTableCell = index.indexOf(':') > -1;
  const isColumnNode = index.indexOf('-') > -1;
  if (isColumnNode) {
    const [gridIndex, colIndex, sectionIndex] = index.split('-');
    if (isTableCell) {
      const [tableIndex, tableRowIndex, tableColIndex] =
        sectionIndex.split(':');
      const tableColumn =
        sections[gridIndex]['columns'][colIndex][tableIndex]['rows'][
          tableRowIndex
        ];
      if (tableColumn) {
        section = tableColumn[tableColIndex];
      }
    } else {
      section = sections[gridIndex]['columns'][colIndex][sectionIndex];
    }
  } else {
    if (isTableCell) {
      const [tableIndex, tableRowIndex, tableColIndex] = index.split(':');
      const tableColumn = sections[tableIndex]['rows'][tableRowIndex];
      if (tableColumn) {
        section = tableColumn[tableColIndex];
      }
    } else {
      section = sections[index];
    }
  }

  return section || {};
};

export const escapeString = (title: string) => title.replace(/"/g, '\\"');

const validateImageExtension = (extension: string): boolean =>
  ['jpg', 'png', 'jpeg'].includes(extension);

export const getImageErrorText = (
  MIN_IMAGE_HEIGHT,
  MIN_IMAGE_WIDTH,
  height,
  width,
  extension,
  size = 0
) => {
  if (!validateImageExtension(extension)) {
    return INVALID_IMAGE_EXTENSION;
  }
  if (size > MAX_FILE_SIZE) {
    return ERROR_INVALID_SIZE;
  }
  if (MIN_IMAGE_HEIGHT > height || MIN_IMAGE_WIDTH > width) {
    return getInvalidDimensionError(MIN_IMAGE_HEIGHT, MIN_IMAGE_WIDTH);
  }
  return '';
};

export const getSelectedImageErrorText = (file: Object) => {
  const { name, size } = file;
  if (!validateSize(size)) {
    return ERROR_INVALID_SIZE;
  }

  const extension = getImageExtension(name);
  if (!!extension && !validateImageExtension(extension)) {
    return INVALID_IMAGE_EXTENSION;
  }

  return '';
};

export const getCurrency = (adminUrl: string) => {
  return isMexicoSite(adminUrl)
    ? 'MXN'
    : isEnglishSite(adminUrl)
    ? 'USD'
    : 'EUR';
};
