// @flow
import { searchEcommerceProduct } from 'components/Editor/Escribir/lib/service';
import {
  getAsinFromUrl,
  isValidAsin,
  getEbayIdFromUrl,
  isEbayId,
} from 'components/Editor/Escribir/lib/helpers';
import { isValidUrl } from 'lib/helpers';
import { StoresType } from 'lib/flowTypes';

const stores = {
  'www.fnac.es': 'FNAC',
  'www.mediamarkt.es': 'MediaMarkt',
  'www.pccomponentes.com': 'PcComponentes',
  'www.microsoft.com': 'Microsoft Store',
  'www.ibuygou.com': 'ibuygou',
  'www.banggood.com': 'Banggood',
  'www.geekbuying.com': 'Geekbuying',
  'www.dvdgo.com': 'DvdGo',
  'store.hp.com': 'HP Store',
  'www.phonehouse.es': 'ThePhoneHouse',
  'www.lightinthebox.com': 'Lightinthebox',
  'www.zavvi.es': 'Zavvi',
  'www.gamiss.com': 'Gamiss',
  'www.alternate.es': 'Alternate',
  'www.tuimeilibre.com': 'tuimeilibre',
  'www.mequedouno.com': 'mequedouno',
  'www.macnificos.com': 'Macníficos',
  'www.mielectro.es': 'Mi electro',
  'eshop.asus.com': 'Asus',
  'es.edwaybuy.com': 'Edwaybuy',
  'www.acer.com': 'Acer',
  'www.hihonor.com': 'Honor',
  'consumer.huawei.com': 'Huawei',
  'www.lenovo.com': 'Lenovo',
  'www.philips.es': 'Philips',
  'www.tecnofactorytehabla.com': 'Tecno Factory',
  'www.samsung.com': 'Samsung',
  'movilplanet.eu': 'Movil Planet',
  'www.tiendatr.com': 'Tiendtr',
  'shop.panasonic.es': 'Panasonic',
  'www.mi.com': 'Xiaomi',
  'www.apple.com': 'Apple',
  'es.aliexpress.com': 'AliExpress',
  'sale.aliexpress.com': 'AliExpress',
  'campaign.aliexpress.com': 'AliExpress',
  'plaza.aliexpress.com': 'AliExpress',
  'www.miniinthebox.com': 'Miniinthebox',
  'www.gearbest.com': 'Gearbest',
  'es.gearbest.com': 'Gearbest',
  'beta.elcorteingles.com': 'El Corte Inglés',
  'www.elcorteingles.es': 'El Corte Inglés',
  'www.amazon.es': 'Amazon',
  'www.ebay.es': 'Ebay',
  'www.leroymerlin.es': 'Leroy Merlin',
  'www.amazon.com.mx': 'Amazon',
  'www.backmarket.es': 'Back Market',
  'www.dyson.es': 'Dyson',
  'www.oneplus.com': 'OnePlus',
  'www.rowenta.es': 'Rowenta',
  'www.amazon.com': 'Amazon',
};

export const isValid = (productVersions: Array<Object>) => {
  const versionSet = new Set();
  let versionCount = 0;
  productVersions.forEach((version) => {
    const RAM = version.RAM || '';
    const ROM = version.ROM || '';
    if (ROM || RAM) {
      versionCount++;
      versionSet.add(`${RAM}-${ROM}`);
    }
  });
  return versionSet.size === versionCount;
};

export const removeEmptyStores = (stores: Array<Object>) => {
  let filteredStores = [];
  if (stores) {
    stores.forEach(({ actualPrice, ...store }) => {
      const { price = null, name = '', link = '' } = store;
      if (null !== price && '' !== name.trim() && '' !== link.trim()) {
        filteredStores.push(store);
      }
    });
  }
  return filteredStores;
};

export const filterEmptyStores = (versions: Array<Object>): Array<Object> => {
  return versions.map(({ stores = [], ...rest }) => {
    let filteredStores = removeEmptyStores(stores);
    filteredStores = filteredStores.map((store) => {
      delete store.nonZeroPriceFetched;
      return store;
    });
    return {
      ...rest,
      stores: filteredStores,
    };
  });
};

export const getMessages = (versions: Array<Object>) => {
  let versionMessage = [];
  versions.forEach((version) => {
    if (version.message) {
      versionMessage.push(version.message);
    }
  });
  return versionMessage;
};

export const removeMessages = (versions: Array<Object>) => {
  let updatedVersions = [];
  versions.forEach((version) => {
    const productVersion = { ...version };
    delete productVersion.message;
    if (productVersion.RAM || productVersion.ROM) {
      updatedVersions.push({ ...productVersion });
    }
  });
  return updatedVersions;
};

const getFormatedValue = (value) => {
  const storageValue = value || 0;
  return new Intl.NumberFormat('de-DE').format(parseFloat(storageValue / 1000));
};

const getPartialStorageVersion = (type, value) => {
  const storageValue = value || 0;
  if (storageValue >= 1000) {
    return `${type} ${getFormatedValue(storageValue)} TB`;
  }
  return `${type} ${storageValue} GB`;
};

const getFullStorageVersion = (RAM, ROM) => {
  if (ROM >= 1000 && RAM >= 1000) {
    return `${getFormatedValue(RAM)} / ${getFormatedValue(ROM)} TB`;
  }
  if (ROM >= 1000) {
    return `${RAM} GB / ${getFormatedValue(ROM)} TB`;
  }
  if (RAM >= 1000) {
    return `${getFormatedValue(RAM)} TB / ${ROM} GB`;
  }
  return `${RAM} / ${ROM} GB`;
};

export const getVersion = (RAM: number | null, ROM: number | null) => {
  if (RAM == null && ROM == null) {
    return '';
  }
  if (RAM == null) {
    return getPartialStorageVersion('ROM', ROM);
  }
  if (ROM == null) {
    return getPartialStorageVersion('RAM', RAM);
  }
  return getFullStorageVersion(RAM, ROM);
};

export const getEditMessage = (
  RAM: number | null,
  ROM: number | null,
  newRAM: number | null,
  newROM: number | null
) => {
  const version = getVersion(RAM, ROM);
  const newVersion = getVersion(newRAM, newROM);
  if (version === newVersion) {
    return null;
  }
  return `Se ha modificado la versión [ ${version} ] a [ ${newVersion} ] y sus ofertas se han mantenido sin cambios`;
};

export const getDeleteMessage = (
  RAM: number | null,
  ROM: number | null,
  stores: StoresType
) => {
  const version = getVersion(RAM, ROM);
  if (!stores.length) {
    return `Se ha eliminado la versión [ ${version} ]`;
  }
  const totalOffers =
    stores.length === 1 ? `su 1 oferta` : `sus ${stores.length} ofertas`;
  return `Se ha eliminado la versión [ ${version} ] y ${totalOffers}`;
};

export const getHintText = (index: number) => {
  if (0 === index) {
    return 'https://www.amazon.c';
  }

  if (1 === index) {
    return 'https://www.ebay.es/it';
  }

  return '';
};

export const handleProductSearch = async (
  store: string,
  adminUrl: string,
  searchItem: string
) => {
  let criteria = 'keywords';
  if ('Amazon' === store && '' !== getAsinFromUrl(searchItem)) {
    searchItem = getAsinFromUrl(searchItem);
    criteria = 'asin';
  } else if ('Amazon' === store && isValidAsin(searchItem)) {
    criteria = 'asin';
  } else if ('Ebay' === store && '' !== getEbayIdFromUrl(searchItem)) {
    searchItem = getEbayIdFromUrl(searchItem);
    criteria = 'id';
  } else if ('Ebay' === store && isEbayId(searchItem)) {
    criteria = 'id';
  }

  return await searchEcommerceProduct(adminUrl, store, criteria, searchItem);
};

export const getStoreNameFromUrl = (url: string) => {
  const urlPattern = /^https?:\/\/([\w.-]+)/i;
  const match = url.match(urlPattern);
  if (!match) {
    return '';
  }

  if (stores.hasOwnProperty(match[1])) {
    return stores[match[1].toLowerCase()];
  }

  return '';
};

export const getStoreLabel = (index: number) => {
  if (0 === index) {
    return 'AMAZON';
  }

  if (1 === index) {
    return 'EBAY';
  }

  return 'OTRAS';
};

export const isValidSearch = (search: string, index: number) => {
  if ('' === search.trim()) {
    return false;
  }
  if (index > 1) {
    return isValidUrl(search);
  }

  return true;
};
