// @flow
import * as React from 'react';
import MuiTextField from '@material-ui/core/TextField';
import { InputProps as StandardInputProps } from '@material-ui/core/Input';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';

type Props = {
  floatingLabelText?: string,
  value?: string,
  hintText?: string,
  error?: boolean,
  fullWidth?: boolean,
  errorText?: string,
  helperText?: string,
  textLimit?: number,
  type?: string,
  symbol?: string,
  startAdornmentText?: string,
  endAdornmentText?: string,
  onEnter?: Function,
  readOnly?: boolean,
  InputProps?: StandardInputProps,
};

const ENTER_KEY_CODE = 13;

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    error: {
      main: '#C52100',
    },
  },
  overrides: {
    MuiInputLabel: {
      root: {
        whiteSpace: 'nowrap',
        color: '#757575',
      },
    },

    MuiOutlinedInput: {
      root: {
        background: 'white',
      },

      input: {
        padding: '10px 14px 6px !important',
        minHeight: '40px',
        boxSizing: 'border-box',
      },

      inputAdornedStart: {
        paddingLeft: '0px !important',
      },

      inputAdornedEnd: {
        paddingRight: '0px !important',
        overflow: 'hidden',
      },

      multiline: {
        padding: '10px 14px 6px !important',
      },

      inputMultiline: {
        overflow: 'auto !important',
        padding: '0 !important',
      },
    },
    MuiInput: {
      root: {
        color: 'inherit',
      },
      underline: {
        '&:before': {
          borderBottom: `1px solid rgba(0, 0, 0, 0.42)`,
        },
      },
    },

    MuiInputBase: {
      multiline: {
        overflow: 'auto !important',
      },

      root: {
        '&$disabled': {
          background: '#f6f6f6',
        },
      },
    },

    MuiFormControl: {
      marginDense: {
        marginBottom: '0',
        marginTop: '0 !important',
      },
    },

    MuiFormHelperText: {
      root: {
        color: '#757575',
        letterSpacing: 'normal',
        lineHeight: '1.35',

        '&$error': {
          marginLeft: '12px',
        },
      },
      marginDense: {
        marginTop: '4px',
        marginLeft: '12px',
      },
      contained: {
        marginRight: '0',
        marginLeft: '12px',
      },
    },
  },
});

const themeAdornment = createTheme({
  palette: {
    text: {
      secondary: '#757575',
    },
  },
  overrides: {
    MuiInputAdornment: {
      root: {
        whiteSpace: 'nowrap',
        paddingBottom: '4px',
        position: 'relative',
        top: '4px',

        '&.MuiInputAdornment-positionEnd': {
          position: 'relative',
          top: '4px',
        },
      },
    },
  },
});

const readOnlyTheme = createTheme({
  ...theme,
  overrides: {
    MuiInputLabel: {
      root: {
        whiteSpace: 'nowrap',
        color: '#a4a4a4',
      },
    },

    MuiFormControl: {
      root: {
        color: 'rgba(0, 0, 0, 0.87)',
      },

      marginDense: {
        marginBottom: '0',
        marginTop: '0 !important',
      },
    },

    MuiOutlinedInput: {
      root: {
        background: '#F6F6F6',
      },

      input: {
        padding: '10px 14px 6px !important',
        minHeight: '40px',
        boxSizing: 'border-box',
      },

      inputAdornedStart: {
        paddingLeft: '0px !important',
      },

      inputAdornedEnd: {
        paddingRight: '0px !important',
        overflow: 'hidden',
      },
    },

    MuiInputAdornment: {
      root: {
        whiteSpace: 'nowrap',
        paddingBottom: '4px',
        position: 'relative',
        top: '4px',

        '&.MuiInputAdornment-positionEnd': {
          position: 'relative',
          top: '4px',
        },
      },
    },
  },
});

type NumberFormatCustomProps = {
  inputRef: Function,
  onChange: Function,
  type: string,
  name?: string,
};

function NumberFormatDollar(props: NumberFormatCustomProps) {
  const { t } = useTranslation();
  const { inputRef, onChange, ...other } = props;

  const thousandSeparator = t('thousandSeparator');
  const decimalSeparator = t('decimalSeparator');
  return (
    <NumberFormat
      {...other}
      type="text"
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value.trim(),
          },
        });
      }}
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      decimalScale={2}
      fixedDecimalScale
      isNumericString
    />
  );
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { t } = useTranslation();
  const { inputRef, onChange, type, ...other } = props;

  const params = {};

  if (type === 'decimal' || type === 'fixedPrice') {
    params.fixedDecimalScale = true;
    params.decimalScale = 2;
  } else if (type === 'integer') {
    params.decimalScale = 0;
  } else if (type === 'singleDecimal') {
    params.fixedDecimalScale = true;
    params.decimalScale = 1;
  }

  const thousandSeparator = t('thousandSeparator');
  const decimalSeparator = t('decimalSeparator');
  return (
    <NumberFormat
      {...other}
      {...params}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
            name: props.name,
          },
        });
      }}
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      isNumericString
      allowNegative={false}
    />
  );
}

export const TextField = (props: Props): React.Node => {
  const {
    hintText,
    floatingLabelText = '',
    errorText,
    error,
    fullWidth = true,
    helperText,
    textLimit = 0,
    startAdornmentText = '',
    endAdornmentText = '',
    type = '',
    symbol = '',
    onEnter,
    readOnly = false,
    ...restProps
  } = props;
  let label = floatingLabelText;

  let InputProps: StandardInputProps = restProps.InputProps;

  let inputProps = {};
  if (textLimit) {
    const textLength = restProps.value ? restProps.value.length : 0;
    label += ` [${textLimit - textLength}]`;
    inputProps = {
      maxLength: textLimit,
    };
  }

  InputProps = {
    ...InputProps,
    readOnly: readOnly,
  };

  if (startAdornmentText) {
    InputProps = {
      startAdornment: (
        <MuiThemeProvider theme={themeAdornment}>
          <InputAdornment position="start">{startAdornmentText}</InputAdornment>
        </MuiThemeProvider>
      ),
      ...InputProps,
    };
  }

  if (endAdornmentText) {
    InputProps = {
      endAdornment: (
        <MuiThemeProvider theme={themeAdornment}>
          <InputAdornment position="end">{endAdornmentText}</InputAdornment>
        </MuiThemeProvider>
      ),
      ...InputProps,
    };
  }

  if ('price' === type || 'fixedPrice' === type) {
    if (symbol === '$') {
      InputProps = {
        ...InputProps,
        startAdornment: (
          <InputAdornment position="start">{symbol}</InputAdornment>
        ),
        inputComponent: NumberFormatDollar,
      };
    } else {
      InputProps = {
        ...InputProps,
        endAdornment: <InputAdornment position="end">{symbol}</InputAdornment>,
        inputComponent: NumberFormatCustom,
      };
    }
  } else if (
    ['decimal', 'freeDecimal', 'integer', 'singleDecimal'].includes(type)
  ) {
    InputProps = {
      ...InputProps,
      inputComponent: NumberFormatCustom,
    };
  }

  const onKeyPress = (e: KeyboardEvent) => {
    const key = e.which || e.keyCode;
    if (key === ENTER_KEY_CODE && onEnter) {
      onEnter();
    }
  };

  return (
    <MuiThemeProvider theme={readOnly ? readOnlyTheme : theme}>
      {/* $FlowFixMe This comment suppresses a Flow error */}
      <MuiTextField
        InputLabelProps={{
          shrink: true,
        }}
        placeholder={hintText}
        fullWidth={fullWidth}
        margin="dense"
        label={label}
        helperText={!!errorText ? errorText : helperText}
        error={error || !!errorText}
        InputProps={InputProps}
        // eslint-disable-next-line
        inputProps={inputProps}
        variant="outlined"
        type={type}
        onKeyPress={onKeyPress}
        {...restProps}
      />
    </MuiThemeProvider>
  );
};
