// @flow
import * as React from 'react';
import { ErrorIcon } from 'components/Editor/Common/Icons';
import { getImageExtension } from 'lib/helpers';
import styles from './Errors.module.css';

type Props = {
  erroredFiles: Object,
  mode?: string,
};

export const Errors = ({
  erroredFiles = {},
  mode = '',
}: Props): null | React.Element<'ul'> => {
  const {
    invalidSizeFiles = [],
    invalidExtensionFiles = [],
    failedFiles = [],
    invalidDimensionFile = '',
  } = erroredFiles;
  let errors = [];

  const setInvalidSizeError = () => {
    if (0 === invalidSizeFiles.length) {
      return;
    }

    if (
      mode === 'landingImage' &&
      getImageExtension(invalidSizeFiles[0]) === 'gif'
    ) {
      const message =
        invalidSizeFiles.length > 1
          ? `Error: ${invalidSizeFiles.join(', ')} son mayores de 300KB`
          : `Error: ${invalidSizeFiles[0]} es mayor de 300KB`;
      errors.push(message);
    } else {
      const message =
        invalidSizeFiles.length > 1
          ? `Error: ${invalidSizeFiles.join(', ')} son mayores de 8MB`
          : `Error: ${invalidSizeFiles[0]} es mayor de 8MB`;
      errors.push(message);
    }
  };

  const setInvalidExtensionError = () => {
    if (0 === invalidExtensionFiles.length) {
      return;
    }

    const message =
      invalidExtensionFiles.length > 1
        ? `Error: ${invalidExtensionFiles.join(
            ', '
          )} no son imágenes válidas. Puedes usar JPG, PNG y GIF`
        : `Error: ${invalidExtensionFiles[0]} no es una imágen válida. Puedes usar JPG, PNG y GIF`;

    errors.push(message);
  };

  const setFailedFilesError = () => {
    if (0 === failedFiles.length) {
      return;
    }

    const message =
      failedFiles.length > 1
        ? `Error: no hemos podido guardar las imagenes: ${failedFiles.join(
            ', '
          )}. Por favor, intenta subirlas de nuevo`
        : `Error: no hemos podido guardar la imagen: ${failedFiles[0]}. Por favor, intenta subirla de nuevo`;

    errors.push(message);
  };

  const setInvalidWidthError = () => {
    if (invalidDimensionFile.length === 0) {
      return;
    }
    const message =
      'Error: la imagen principal debe tener al menos 1200x900 px';
    errors.push(message);
  };

  setInvalidSizeError();
  setInvalidExtensionError();
  setFailedFilesError();
  setInvalidWidthError();

  return errors.length === 0 ? null : (
    <ul>
      <div className={styles.uploaderError}>
        {errors.map((error, i) => (
          <li key={i}>
            <ErrorIcon />
            {error}
          </li>
        ))}
      </div>
    </ul>
  );
};
